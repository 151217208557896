<template>
    <v-form :model="isValid" ref="form">
        <div class="address">
            <div class="grid grid-cols-6 gap-x-2">
                <div class="col-span-6">
                    <div class="v-text-field v-input with-search-icon">
                        <fa-icon icon="search" />
                        <gmap-autocomplete
                            :id="id"
                            class="w-full"
                            label="Enter an address"
                            :options="autocompleteOptions"
                            :select-first-on-enter="true"
                            :readonly="readOnly"
                            @place_changed="getAddressData"
                            @keyup="autoCompleteKeyupHandler($event)"
                        >
                        </gmap-autocomplete>
                    </div>
                </div>
                <div class="col-span-1">
                    <v-text-field
                        disabled
                        class="pointer-events-none"
                        v-model="addressForm.unit"
                        label="Unit"
                        :readonly="true"
                    ></v-text-field>
                </div>
                <div class="col-span-5">
                    <v-text-field
                        disabled
                        class="pointer-events-none"
                        v-model="addressForm.addressLine1"
                        label="Address line 1"
                        :readonly="true"
                    ></v-text-field>
                </div>
                <div class="col-span-6">
                    <v-text-field
                        disabled
                        class="pointer-events-none"
                        v-model="addressForm.addressLine2"
                        label="Address line 2"
                        :readonly="true"
                    ></v-text-field>
                </div>
                <div class="col-span-2">
                    <v-text-field
                        disabled
                        class="pointer-events-none"
                        v-model="addressForm.suburb"
                        label="Suburb"
                        :readonly="true"
                        :rules="[required]"
                    ></v-text-field>
                </div>
                <div class="col-span-2">
                    <v-text-field
                        disabled
                        class="pointer-events-none"
                        v-model="addressForm.postcode"
                        label="Postcode"
                        :rules="[required, numbersOnly]"
                    ></v-text-field>
                </div>
                <div class="col-span-2">
                    <v-select
                        disabled
                        class="pointer-events-none"
                        :items="states"
                        ref="state"
                        v-model="addressForm.state"
                        label="State"
                        data-cy="addressState"
                        :readonly="true"
                        :rules="[required]"
                    ></v-select>
                </div>
            </div>
        </div>
    </v-form>
</template>

<script>
import { reactive, toRefs, onMounted } from '@vue/composition-api'
import { mapState } from 'vuex'
import { getGoogleMapsAPI } from 'gmap-vue'
import { FormRules } from '@/components/shared/mixins/formMixins'

export default {
    name: 'Address',
    mixins: [FormRules],
    props: {
        id: {
            type: String,
            default: 'address'
        },
        value: {
            type: Object,
            required: true,
            default: () => {
                return {
                    unit: '',
                    addressLine1: '',
                    addressLine2: '',
                    suburb: '',
                    postcode: '',
                    state: '',
                    lat: null,
                    lng: null,
                    archived: false
                }
            }
        },
        title: {
            type: String,
            default: ''
        },
        readOnly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isValid: true,
            googleAddress: '',
            defaultAddress: {
                unit: '',
                addressLine1: '',
                addressLine2: '',
                suburb: '',
                postcode: '',
                state: '',
                lat: null,
                lng: null,
                archived: false
            },
            autocompleteOptions: {
                componentRestrictions: {
                    country: ['au']
                }
            },
            geoCodedAddressTimer: null,
            addressFound: true,
            initialPageLoad: true
        }
    },
    mounted() {
        // setting the addressFound value on first load
        this.addressFound = false
    },
    methods: {
        validate() {
            this.isValid = this.$refs.form.validate()
            return this.isValid
        },
        getAddressData(addressData, placeResultData, id) {
            // this.$refs.form.reset()
            this.localAddress = addressData

            this.validate()
        },
        autoCompleteKeyupHandler(event) {
            // we check the target value to set this so we know when to get the geocode
            this.addressFound = !!event.target.value
        },
        manualAddressChange() {
            this.localAddress.lat = null
            this.localAddress.lng = null
        },
        mapAddressData(addressList) {
            const mappedObj = { ...this.defaultAddress }
            if (!addressList) return null

            for (let i = 0; i < addressList.length; i++) {
                let key = ''
                switch (addressList[i].types[0]) {
                    case 'street_number':
                        key = 'streetNumber'
                        break
                    case 'route':
                        key = 'street'
                        break
                    case 'locality':
                        key = 'suburb'
                        break
                    case 'administrative_area_level_1':
                        key = 'state'
                        break
                    case 'postal_code':
                        key = 'postcode'
                        break
                }
                mappedObj[key] = addressList[i].short_name
            }
            return mappedObj
        },
        geocodedAddress() {
            let address = `${this.localAddress.street || ''} ${this.localAddress.address2 || ''}, ${
                this.localAddress.suburb || ''
            } ${this.localAddress.state || ''}, ${this.localAddress.postcode || ''}`
            let geocoder = new this.google.maps.Geocoder()

            const that = this
            geocoder.geocode(
                {
                    address: address
                },
                (results, status) => {
                    if (status == that.google?.maps.GeocoderStatus.OK) {
                        const latitude = results[0].geometry.location.lat()
                        const longitude = results[0].geometry.location.lng()

                        that.localAddress.lat = that.addressForm.lat = latitude
                        that.localAddress.lng = that.addressForm.lng = longitude
                    }
                }
            )
        },
        getPostcode(place) {
            const addressComponents = place.address_components
            const postcodeComponent = addressComponents.find((component) =>
                component.types.includes('postal_code')
            )
            return postcodeComponent ? postcodeComponent.long_name : 'N/A'
        },
        fetchPostcodeFromGeocoding(location) {
            const geocoder = new this.google.maps.Geocoder()
            geocoder.geocode({ location }, (results, status) => {
                if (status === this.google.maps.GeocoderStatus.OK && results[0]) {
                    const addressComponents = results[0].address_components
                    const postcodeComponent = addressComponents.find((component) =>
                        component.types.includes('postal_code')
                    )
                    let postcode = postcodeComponent ? postcodeComponent.long_name : 'N/A'
                    this.addressForm.postcode = postcode
                } else {
                    console.error('Geocoding request failed:', status)
                }
            })
        }
    },
    computed: {
        ...mapState({
            staticChoices: (state) => state.app.staticChoices
        }),
        google: getGoogleMapsAPI,
        localAddress: {
            get() {
                console.log('getting localAddress so sending addressForm')
                return this.addressForm
            },
            set(addressData) {
                let addressObject = this.mapAddressData(addressData.address_components)
                if (addressData) {
                    this.addressForm.addressLine1 = addressObject.addressLine1
                    this.addressForm.addressLine2 = addressObject.addressLine2

                    if (addressObject.streetNumber != null && addressObject.street != null) {
                        this.addressForm.addressLine1 =
                            addressObject.streetNumber + ' ' + addressObject.street
                    }
                    this.addressForm.suburb = addressObject.suburb
                    this.addressForm.state = addressObject.state
                    this.addressForm.postcode = addressObject.postcode
                    this.addressForm.lng = addressData.geometry.location.lng()
                    this.addressForm.lat = addressData.geometry.location.lat()

                    this.addressFound = true
                } else {
                    this.addressFound = false
                }
                // let's check if we have a postcode, if we don't then we need to search for it using the place_id
                if (!this.addressForm.postcode) {
                    // we use the 'div' as a dummy element for the google places service to attach to
                    // it doesn't actually render
                    const service = new this.google.maps.places.PlacesService(
                        document.createElement('div')
                    )
                    service.getDetails(
                        {
                            placeId: addressData.place_id,
                            fields: ['address_components', 'name', 'formatted_address', 'geometry']
                        },
                        (result, status) => {
                            if (status === this.google.maps.places.PlacesServiceStatus.OK) {
                                // this.placeDetails = result
                                const postcode = this.getPostcode(result)
                                if (postcode === 'N/A') {
                                    this.fetchPostcodeFromGeocoding(result.geometry.location)
                                }
                            } else {
                                console.error('Place details request failed:', status)
                            }
                        }
                    )
                }
                const address = { ...this.addressForm }
                this.$emit('input', address)
            }
        },
        addressForm: {
            get() {
                console.log('value passed', this.value)
                return this.value || this.defaultAddress
            },
            set(addressForm) {
                this.$emit('input', addressForm)
            }
        },
        states() {
            return this.staticChoices['address.state'] || []
        }
    },
    watch: {
        addressForm: {
            deep: true,
            handler() {
                // if it's the initial page load then we stop what we're doing cause we don't need the geocoded stuff
                if (this.initialPageLoad) {
                    this.initialPageLoad = false
                    return
                }
                // we only do this if we didn't get the value from google maps
                if (this.geoCodedAddressTimer) clearTimeout(this.geoCodedAddressTimer)
                if (!this.addressFound) {
                    this.geoCodedAddressTimer = setTimeout(() => {
                        this.geocodedAddress()
                    }, 3000)
                }

                if (this.addressForm && this.addressForm.street !== '') {
                    this.$emit('input', this.addressForm)
                }
            }
        }
    },
    setup() {
        const state = reactive({})
        onMounted(() => {})
        return {
            ...toRefs(state)
        }
    }
}
</script>
