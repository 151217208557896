import Vue from 'vue'
import './plugins/installCompositionApi'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import { getOrCreateProvider, client } from '@/vue-apollo'
import vuetify from '@/plugins/vuetify'
import VuePhoneNumberInput from 'vue-phone-number-input'
import VueCookies from 'vue-cookies'
import ElementUI from 'element-ui'
import VueAnime from '@/plugins/anime'
import locale from 'element-ui/lib/locale/lang/en'
import VueCardFormat from 'vue-credit-card-validation'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { Auth0Plugin } from "./auth"
import VueFriendlyIframe from 'vue-friendly-iframe'
import '@/utils/sentry'
import 'regenerator-runtime';
import { createPinia, PiniaVuePlugin } from 'pinia'

// google autocomplete
import GmapVue from 'gmap-vue'
import ability from './plugins/ability'

// font awesome
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { faComment, faSquare, faCircle, faDotCircle } from '@fortawesome/free-regular-svg-icons'
import { faExclamationTriangle, faUserSecret, faPlus, faPencilAlt, faMapMarkerAlt, faPhoneAlt, faUser, faFileAlt, faQuestionCircle, faExternalLinkAlt, faTrashAlt, faDownload, faPaperclip, faFilter, faChevronLeft, faChevronDown, faChevronRight, faCheck, faSignOutAlt, faLongArrowAltRight, faCheckSquare, faLock, faTimes, faCog, faCalendarAlt, faBan, faExclamationCircle, faBars, faEnvelope, faCaretDown, faTimesCircle, faSortUp, faSearch, faStar, faRedo, faMinusCircle, faCheckCircle, faCircle as fasCircle, faMinusSquare, faClock, faUsers, faHouseUser, faSmile, faSyncAlt, faTransgenderAlt, faSignLanguage, faGavel, faBriefcaseMedical, faHeartbeat, faBaby, faClipboardCheck, faUserFriends, faComments, faWineBottle, faTasks, faUserCheck, faSignInAlt, faFolderOpen, faSpinner, faLink } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// project scss
import './css/font-awesome.min.css'
import 'vuetify/dist/vuetify.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@/scss/main.scss'
// import { provide } from '@vue/composition-api'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { provide } from '@vue/composition-api'
import { abilitiesPlugin } from '@casl/vue'

// Use libraries
Vue.use(ElementUI, { locale })
Vue.use(VueCardFormat)
Vue.use(VueAnime)

//config.autoAddCss = false
library.add(faExclamationTriangle, faUserSecret, faPlus, faPencilAlt, faMapMarkerAlt, faPhoneAlt, faUser, faFileAlt, faQuestionCircle, faExternalLinkAlt, faTrashAlt, faDownload, faComment, faPaperclip, faFilter, faChevronLeft, faChevronDown, faChevronRight, faCheck, faSignOutAlt, faCheckSquare, faLongArrowAltRight, faLock, faTimes, faCog, faCalendarAlt, faCheckSquare, faBan, faExclamationCircle, faBars, faSquare, faEnvelope, faCaretDown, faTimesCircle, faSortUp, faSearch, faStar, faRedo, faCircle, faMinusCircle, faCheckCircle, faDotCircle, fasCircle, faMinusSquare, faClock, faUsers, faHouseUser, faSmile, faSyncAlt, faTransgenderAlt, faSignLanguage, faGavel, faBriefcaseMedical, faHeartbeat, faBaby, faClipboardCheck, faUserFriends, faComments, faWineBottle, faTasks, faUserCheck, faSignInAlt, faFolderOpen, faSpinner, faLink)

// Add libraries
Vue.component('fa-icon', FontAwesomeIcon)
Vue.component('font-awesome-icon', FontAwesomeIcon) // cause vuetify requires it to be this tag
Vue.component('v-phone-input', VuePhoneNumberInput)

Vue.use(VueCookies)
Vue.use(VueFriendlyIframe)

Vue.use(abilitiesPlugin, ability, {
    useGlobalProperties: true
})

Vue.config.productionTip = false
Vue.router = router

Vue.use(VueAxios, axios)

Vue.use(GmapVue, {
    load: {
        key: process.env.VUE_APP_GMAP_API_KEY,
        libraries: 'places'
    },
})

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

const scopes = process.env.VUE_APP_AUTH0_SCOPES.split(',').join(' ')
Vue.use(Auth0Plugin, {
    domain: process.env.VUE_APP_AUTH0_DOMAIN,
    clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
    audience: process.env.VUE_APP_AUTH0_AUDIENCE?.split(','),
    advancedOptions: {
        defaultScope: scopes // change the scopes that are applied to every authz request. **Note**: `openid` is always specified regardless of this setting
    },
    onRedirectCallback: appState => {
        router.push(
            appState && appState.targetUrl
                ? appState.targetUrl
                : window.location.pathname
        );
    }
});

Vue.axios.defaults.baseURL = process.env.VUE_APP_AUTH_HTTP || ''

const app = new Vue({
    apolloProvider: getOrCreateProvider(),
    store,
    pinia,
    router,
    vuetify,
    setup() {
        provide(DefaultApolloClient, client)
        provide('vuex-store', store)
    },
    render: h => h(App)
})

app.$mount('#app')
